/* ManageUsers.css */

.manage-users-container {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading-bar {
    background-color: #61acb4;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

.page-title {
    font-size: 24px;
    color: white;
    margin: 0;
}

.error-text {
    color: red;
    margin-bottom: 15px;
    text-align: center;
}

.users-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.users-table th, .users-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.users-table th {
    background-color: #61acb4;
    color: white;
}

.users-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.users-table tr:hover {
    background-color: #ddd;
}

.add-user-container {
    text-align: right;
    margin-bottom: 10px;
}

.add-user-button {
    background-color: #61acb4;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.add-user-button:hover {
    background-color: #61acb4;
}

.add-user-form {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.delete-user-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
}

.delete-user-button:hover {
    background-color: #e60000;
}
