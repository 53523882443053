/* ManageProviders.css */

.manage-providers-container {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading-bar {
    background-color: #61acb4;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

.page-title {
    font-size: 24px;
    color: white;
    margin: 0;
}

.providers-list-container {
    margin-bottom: 20px;
}

.providers-list {
    list-style-type: none;
    padding: 0;
}

.provider-item {
    margin-bottom: 10px;
}

.provider-label {
    font-size: 18px;
    color: #2c3e50;
}

.save-selection-button {
    background-color: #61acb4;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
}

.save-selection-button:hover {
    background-color: #61acb4;
}

.message-text {
    color: green;
    margin-top: 15px;
    text-align: center;
}
