/* ManageBaseStations.css */

.manage-base-stations-container {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading-bar {
    background-color: #61acb4;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

.page-title {
    font-size: 24px;
    color: white;
    margin: 0;
}

.message-text {
    color: red;
    margin-bottom: 15px;
    text-align: center;
}

.upload-kmz {
    margin-bottom: 20px;
    display: block;
}

.upload-message {
    color: green;
    margin-bottom: 15px;
    text-align: center;
}

.base-stations-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.base-stations-table th, .base-stations-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.base-stations-table th {
    background-color: #61acb4;
    color: white;
}

.base-stations-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.base-stations-table tr:hover {
    background-color: #ddd;
}

.add-base-station-container {
    text-align: right;
    margin-bottom: 10px;
}

.add-base-station-button {
    background-color: #61acb4;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.add-base-station-button:hover {
    background-color: #61acb4;
}

.add-base-station-form {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
